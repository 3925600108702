<template>
    <CModal class="c-modal" :title="modalContent.title" color="principal" :show.sync="internalShowModal" :closeOnBackdrop="false">
      <div class="video-container">
          <img class="image-modal" :src="image" :alt="image.title">
      </div>
      <div slot="footer">
        <CButton
          :color="btn.color"
          v-for="(btn, index) in modalContent.buttons"
          :key="index"
          @click="emitEvent(btn.event)"
          style="margin-right: 5px"
        >
          {{ btn.textButton }}
        </CButton>
      </div>
    </CModal>
</template>
    
<script>
  export default {
    name: "imageModal",
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      image: {
        type: String,
        required: true
      },
      modalContent: {
        type: Object,
        required: true,
        default: {
          buttons: [
            {
              textButton: "Botón",
              color: "primary",
            },
          ],
        },
      },
    },
    data() {
      return {
        internalShowModal: false,
      };
    },
    watch: {
      /**
       * Esta propiedad recoge el valor para mostrar el modal.
       */
      showModal(newVal) {
        this.internalShowModal = newVal;
      },
    },
    methods: {
      /**
       * Evento que se emite al padre al pulsar el botón, recoge el nombre del método de manera dinámica
       */
      emitEvent(eventName, item) {
        this.$emit(eventName, item);
      },
    },
  };
</script>
    
<style>
.c-modal .close{
    visibility: hidden;
}
.image-modal{
    height: 50%;
    width: 100%;
    object-fit: contain;
}
  
</style>