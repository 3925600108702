<template>
    <div>
        <Loader v-if="loading"></Loader>
        <CRow v-else>
            <CCol lg="12">
                <CCard>
                    <CCardHeader class="header">
                        <h3>{{ questTitle }}</h3>
                    </CCardHeader>
                    <CCardBody>
                        <div class="quest-content">
                            <p class="quest-description">{{ questDescription }}</p>
                            <span><strong>Necesidad del hardware:</strong><CIcon class="hardware-icon" :name="(needDevice == 1) ? 'cil-check' : 'cil-x'" /></span>
                            <span><strong>El cuestionario se empezó el día </strong>{{ startDate }} <strong> a la hora </strong>{{ questStartTime }}</span>
                            <span><strong>Y finalizó </strong>{{ endDate }} <strong> a la hora </strong>{{ questEndTime }}</span>
                        </div>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg="12">
                <CCard>
                    <CCardHeader class="header">
                        <span>Limites y grados</span>
                    </CCardHeader>
                    <CCardBody>
                        <div class="patients-degrees">
                            <LimitCard :min="questMin" :minLabel="'Grados mínimos alcanzados'" :max="questMax" :maxLabel="'Grados máximos alcanzados'" />
                        </div>

                        <div class="patients-limits">
                            <LimitCard :min="minimumLimit" :minLabel="'Limite mínimo establecido por el doctor'" :max="maximumLimit" :maxLabel="'Limite máximo establecido por el doctor'" />
                        </div>

                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg="12">
                <CCard class="answers-and-questions">
                    <CCardHeader class="header">
                        <span>Preguntas y respuestas</span>
                    </CCardHeader>
                    <CCardBody>
                        <div v-if="answers != ''">
                            <fieldset class="border p-2 m-2" v-for="(item, index) in answers" :key="index">
                                <div class="question-container">
                                    <span><strong>{{ item.body }}</strong></span>
                                    <div class="answer-container">
                                        <span v-if="item.type == 'text'">
                                            - {{ item.answer }}
                                        </span>
                                        <div v-else-if="item.type == 'photo'">
                                            <img @click="showModal" class="answer-image" width="300" :src="item.answer" alt="'Imagen'" />
                                            <ImageModal :modalContent="modalMultimediaContent" :image="item.answer" :showModal="imageModal" @closeModal="closeModal" @downloadImage="downloadImage(item.answer)"/>
                                        </div>
                                        <div v-else>
                                            <ComponentEVA :selectedEVA="item.answer" />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div v-else>
                            No hay preguntas ni respuestas para este cuestionario.
                        </div>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
  
<script>
import { changeDateFormatTable, getTimeFormat } from '@/utils/date';
import { roundTwoDecimals } from '@/utils/math';
import { getQuestionsAndAnswers } from '@/services/quests';
import {getPacientLimit } from '@/services/patients';
import LimitCard from '@/components/Cards/LimitCard';
import { getImage } from '@/services/images';
import ComponentEVA from '@/components/ComponentEVA.vue';
import Loader from '@/components/Loader.vue';
import ImageModal from '@/components/Modals/ImageModal.vue';
export default {
    name: "questView",
    components: { LimitCard, ComponentEVA, Loader, ImageModal },
    data() {
        return{
            loading: true,
            imageModal: false,
            patientId: null,
            imageDataUrl: null,
            questTitle: '',
            questDescription: '',
            needDevice: 0,
            minimumLimit: 0, 
            maximumLimit: 0,
            questMin: 0,
            questMax: 0,
            startDate: '',
            endDate: '',
            questStartTime: '',
            questEndTime: '',
            answers: [],
            modalMultimediaContent: {
                buttons: [
                    {
                        textButton: "Descargar",
                        color: "primary",
                        event: "downloadImage",
                    },
                    {
                        textButton: "Cerrar",
                        color: "secondary",
                        event: "closeModal",
                    }
                ],
            },
        }
    },
    created() {
        this.receiveData();
    },
    methods: {
        receiveData() {
            this.getQuest();
            this.getLimits();
        },
        /**
         * Función en la que se obtienen el cuestionario y que también
         * llama el metodo receiveData para cargarlos.
         * 
         * @returns {object[]} el cuestionario obtenido.
         */
        getQuest(){
            let data = [];
            getQuestionsAndAnswers(this.$route.params.id, this.$route.params.id_session)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                .then((result) => {

                    this.questTitle = result.title;
                    this.questDescription = result.description;
                    this.needDevice = result.need_device;

                    this.startDate = result.timestamp_init != undefined ? changeDateFormatTable(result.timestamp_init) : '-';
                    this.endDate = result.timestamp_end != undefined ? changeDateFormatTable(result.timestamp_end) : '-';

                    this.questStartTime = getTimeFormat(result.timestamp_init != undefined ? result.timestamp_init : '-');
                    this.questEndTime = getTimeFormat(result.timestamp_end != undefined ? result.timestamp_end : '-');

                    this.questMin = result.min != undefined ? roundTwoDecimals(result.min) : 0;
                    this.questMax = result.max != undefined ? roundTwoDecimals(result.max) : 0;

                    const questPromise = result.questions.map(question => {
                        return new Promise((resolve, reject) => {
                            const element = {
                                body: question.body,
                                answer: question.answer,
                                type: question.type
                            };

                            if (question.type == "photo") {
                              getImage(question.answer)
                                .then(imageUrl => {
                                    element.answer = imageUrl;
                                    resolve(element);
                                    this.loading = false;
                                })
                                .catch(error => {
                                    reject(error);
                                });
                            } else {
                                resolve(element);
                            }
                        });
                    });

                    questPromise.forEach(promise => {
                        promise.then(element => {
                            data.push(element);
                        })
                        .catch(error => {
                            this.$store.state.errorAlert = { status: true, msg: error.toString() };
                            this.loading = false;
                        });
                    });
                    this.answers = data;
                    this.loading = false;
            });
        },
        /**
         * Función en la que se obtienen los limites del paciente  y que también
         * llama el metodo receiveData para cargarlos.
         * 
         * @returns {object[]} Los limites obtenidos.
         */
        getLimits() {
            getPacientLimit(this.$route.params.id)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                .then((result) => {
                    this.minimumLimit = result.min_limit != undefined ? roundTwoDecimals(result.min_limit) : 0;
                    this.maximumLimit = result.max_limit != undefined ? roundTwoDecimals(result.max_limit) : 0;
            });
        },
        /**
         * Función para descargar imagen de paciente.
         */
        downloadImage(imageDataUrl){
            const link = document.createElement('a');
            link.href = imageDataUrl;
            link.download = 'imagen.png';
            link.click();
        },
        /**
         * Función para mostrar el modal de imagen
         */
        showModal(){
            this.imageModal = true;
        },
        /**
         * Función para ocultar el modal de imagen
         */
        closeModal() {
          this.imageModal = false;
        }
    }
};
</script>
<style scoped>
.question-container {
    margin: 0px 10px;
}

.answer-container {
    margin: 8px 20px;
}

.question-container > span{
    font-size: 20px;
}

.answer-container > span{
    font-size: 16px;
}
.answer-image{
    width: 300px;
    object-fit: contain;
    margin-top: 5px;
    cursor: pointer;
}

.quest-content span{
    display: block;
    font-size: 16px;
}

.quest-description{
    font-size: 15px;
}
.hardware-icon{
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 5px;
}
@media (max-width: 639px) {
    .card-header h2{
        font-size: 20px;
    }
    .answers-and-questions .card-body{
        padding: 10px 0;
    }
    .question-container{
        margin: 0 !important;
    }
    .answer-container{
        margin: 8px 0 !important;
    }
    .question-container > span > strong {
        font-size: 18px;
    }   
    .answer-container > span{
        padding: 10px 5px;
        font-size: 14px
    }
    .quest-content span{
        font-size: 14px;
    }
}
@media (max-width: 450px) {
    .question-container > span > strong{
        font-size: 14px;
    }
    .answer-container > span{
        font-size: 13px;
    }
    .answer-image{
        width: 100px;
    }
}
</style>
